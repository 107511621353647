







































































































import Vue from 'vue';
import vtable from '@/components/table.vue'; // TODO: vtable, VForm, vSomething: pick one and stick to it
import { RootState } from '@/store';
import { mapActions } from 'vuex';
import VForm from '@/types/vuetify';
import { CaseType, FileType } from '@/types/sqlite';

export default Vue.extend({
	name: 'CaseList',
	data(): {
		selectedId: number | null;
		editing: CaseType | null;
		dialog: boolean;
		dialogFile: boolean;
		valid: boolean;
		validFile: boolean;
		formItemName: string | null;
		formFile: string | null;
	} {
		return {
			selectedId: null,
			editing: null,
			dialog: false,
			dialogFile: false,
			valid: false,
			validFile: false,
			formItemName: null,
			formFile: null
		};
	},
	components: {
		vtable
	},
	computed: {
		selected(): CaseType | null {
			return this.selectedId
				? this.cases.data.find((v: CaseType) => v.id === this.selectedId)
				: null;
		},
		cases() {
			return {
				headers: [
					{ value: 'name', text: 'Name' },
					{ value: 'actions', text: 'Actions' }
				],
				data: this.$store.state.projectCurrent.cases.data.filter(
					(v: CaseType) => v.status === 1
				)
			};
		},
		filesByCase(): any {
			if (!this.selectedId) return [];
			const filesInsideCase = this.$store.getters.getFilesByCaseId(
				this.selectedId
			);
			return {
				headers: [{ value: 'name', text: 'Name' }],
				data: (this.$store
					.state as RootState).projectCurrent.sources.data.filter(
					(v: FileType) => v.status === 1 && filesInsideCase.includes(v.id)
				)
			};
		},
		filesNotInCase(): any {
			if (!this.selectedId) return [];
			const filesInsideCase = this.$store.getters.getFilesByCaseId(
				this.selectedId
			);
			return (this.$store.state as RootState).projectCurrent.sources.data
				.filter((v) => v.status === 1 && !filesInsideCase.includes(v.id))
				.map((v) => ({ ...v, text: v.name, value: v.id }));
		}
	},
	methods: {
		...mapActions([
			'addCase',
			'updateCase',
			'deleteCase',
			'addCaseFile',
			'deleteCaseFile',
			'updateMemoCase'
		]),
		selectionChange({ item, value }: { item: CaseType; value: boolean }) {
			this.selectedId = value ? item.id : null;
		},
		openNew() {
			this.editing = null;
			if (this.$refs.form) (this.$refs.form as VForm).reset();
			this.dialog = true;
		},
		openEdit(item: CaseType) {
			this.editing = item;
			this.formItemName = this.editing!.name;
			this.dialog = true;
		},
		handleDelete(item: CaseType) {
			if (item.id === this.selectedId) this.selectedId = null;
			this.deleteCase(item);
		},
		save() {
			if (this.editing) {
				const item = { ...this.editing, name: this.formItemName };
				this.updateCase(item);
				this.dialog = false;
			} else {
				this.addCase(this.formItemName);
				this.dialog = false;
			}
		},
		fileById(id: number): any {
			return (this.$store.state as RootState).projectCurrent.sources.data.find(
				(v) => v.id === id
			);
		},
		saveCaseFile() {
			if (!this.formFile || !this.selectedId) return;
			this.addCaseFile({
				fid: Number(this.formFile),
				caseid: this.selectedId,
				selfirst: 0,
				selend: this.fileById(Number(this.formFile)).file.length
			});
			this.dialogFile = false;
		},
		openAddToItem() {
			this.dialogFile = true;
		},
		handleDeleteFromItem(file: FileType) {
			if (!file || !this.selectedId) return;
			this.deleteCaseFile({
				fid: file.id,
				caseid: this.selectedId
			});
		}
	}
});
